import React from 'react'
import * as PropTypes from 'prop-types'
import Input from 'components/forms/elements/input'
import Select from 'components/forms/elements/select'
import Textarea from 'components/forms/elements/textarea'
import { Column, Grid } from 'components/grid'

const StepThree = ({ updateValidationSet }) => {
  return (
    <>
      <Grid>
        <Column columns='1/3'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Event Date'
            name='Date'
            type='date'
            required
          />
        </Column>
        <Column columns='1/3'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Sponsorship Deadline'
            name='Sponsorship Deadline'
            type='date'
            required
          />
        </Column>
        <Column columns='1/3'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Level of Sponsorship'
            name='Level of Sponsorship'
            type='text'
            required
          />
        </Column>
      </Grid>
      <Grid>
        <Column columns='1/3'>
          <Select
            updateValidationSet={updateValidationSet}
            name='Sponsored Before'
            label='Have we sponsored this event before?'
          >
            <option value=''>Select an option</option>
            <option value='yes'>Yes</option>
            <option value='no'>No</option>
          </Select>
        </Column>
        <Column columns='1/3'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Contract or Form'
            name='Contract or Form'
            type='file'
            size='1000000'
          />
        </Column>
      </Grid>
      <Textarea
        updateValidationSet={updateValidationSet}
        label='Message'
        name='Message'
        validationError='Please enter a valid message.'
      />
    </>
  )
}

StepThree.propTypes = {
  updateValidationSet: PropTypes.func.isRequired,
}

export default StepThree
