import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import Form from 'components/forms/sponsorship'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/content'

const SponsorshipPage = ({ data: { heroImage } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Sponsorship Request | Hutson Inc</title>
        <meta
          name='description'
          content='Hutson strives to be a leader in the community. We try to sponsor and assist organizations with events in our local communities as much as possible.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Sponsorship Request',
                'item': 'https://www.hutsoninc.com/sponsorship/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroImage title='Sponsorship Request Form' image={heroImage} />

      <Content>
        <Form />
        <Disclaimer>
          We review the sponsorship/donation requests each month. We try to assist with as many
          events and organizations in our local communities as possible, but due to the volume of
          requests, sometimes we are not able to fulfill all the requests. If we are not able to
          help this year, please feel free to re-apply again next year.
        </Disclaimer>
      </Content>
    </Layout>
  )
}

const Disclaimer = styled.p`
  margin-top: 40px;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "sponsorship-bg.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default SponsorshipPage
