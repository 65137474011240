import React from 'react'
import MultiStepForm from 'components/forms/multi-step-form'
import StepOne from './step-one'
import StepTwo from './step-two'
import StepThree from './step-three'

const steps = [
  { name: 'About You', renderComponent: props => <StepOne {...props} /> },
  { name: 'Event Location', renderComponent: props => <StepTwo {...props} /> },
  { name: 'Event Details', renderComponent: props => <StepThree {...props} /> },
]

const SponsorshipForm = () => {
  return <MultiStepForm steps={steps} name='Sponsorship' />
}

export default SponsorshipForm
