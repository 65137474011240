import React from 'react'
import * as PropTypes from 'prop-types'
import Input from 'components/forms/elements/input'
import State from 'components/forms/elements/state'
import Zip from 'components/forms/elements/zip'
import { Column, Grid } from 'components/grid'

const StepTwo = ({ updateValidationSet }) => {
  return (
    <>
      <Grid>
        <Column columns='1/2'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Address'
            name='Address'
            required
          />
        </Column>
        <Column columns='1/2'>
          <Input updateValidationSet={updateValidationSet} label='City' name='City' required />
        </Column>
      </Grid>
      <Grid>
        <Column columns='1/2'>
          <State updateValidationSet={updateValidationSet} required />
        </Column>
        <Column columns='1/2'>
          <Zip updateValidationSet={updateValidationSet} required />
        </Column>
      </Grid>
    </>
  )
}

StepTwo.propTypes = {
  updateValidationSet: PropTypes.func.isRequired,
}

export default StepTwo
